<template>
  <div class="draft-gear-review">
    <j-form model="drafts.draft" hide-errors>

        <!-- Product Name -->
        <j-control
          name="title"
          label="Product Name"
        />

        <!-- Category -->
        <j-control name="category" />

        <!-- Manufacturer -->
        <j-control name="manufacturer" />
    </j-form>

    <!-- Article Body -->
    <draft-article-body :draft="draft" />
  </div>
</template>

<script>

import DraftArticleBody from './DraftArticleBody'

export default {
  name: 'DraftGearReview',
  components: {
    DraftArticleBody
  },
  props: ['draft']
}

</script>
